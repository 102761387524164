@import '../node_modules/@clout-team/web-components/build/assets/styles/variables.scss';
@import '../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../node_modules/@clout-team/web-components/build/assets/styles/main-colors';
@import 'fonts';

*, *::before, *::after {
  box-sizing: border-box;
}

p {
  margin: 0;
}

iframe {
  display: none!important;
}

a {
  text-decoration: none;
  @include theme-aware('color', 'text-100');

  &:hover, &:active {
    @include theme-aware('color', 'text-100');
  }
}

html {
  height: 100%;
  min-height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  position: relative;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.traffic-light-minimize{
  display: none !important;
}

#window-root .header-right .layout div:first-child{
  display: none !important;
}

.component-status-bar .overlap{
  background-color: rgba(255,255,255,.5);
}

#root {
  height: 100%;
  min-height: 100%;
  position: relative;
  @include theme-aware('color', 'text-100');
  @include theme-aware('background-color', 'bg-body');
}

#dialog-root, #context-root {
  @include theme-aware('color', 'text-100');
}

.scrollbar-default {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-button {
    height: 0;
  }

  &::-webkit-scrollbar-track {
    opacity: 0;
  }

  &::-webkit-scrollbar-track-piece {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    height: 35px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner, &::-webkit-resizer {
    @include theme-aware('background-color', 'text-100');
  }

  &:hover::-webkit-scrollbar-thumb {
    @include theme-aware('background-color', 'text-60');
  }

  overflow: hidden;
  overflow-y: auto;
}

input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
  display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}
