@import '../../../node_modules/@clout-team/web-components/build/assets/styles/variables.scss';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';

//#tooltip-root {
//  display: none !important;
//}

.clout-test-dialog {
  //height: calc(100% + 38px);
  margin-top: -38px;

  &.disable-arrow {
    .component-input-main-wrap {
      .tools-right {
        .component-icon-button {
          opacity: 0.5;
        }
      }
    }
  }

  .component-page-header-type-test {
    .header-center {
      display: flex;
      justify-content: flex-end;
    }
  }

  .component-base-message {
    background: #F3F3F3;

    .card-item-area {
      min-height: unset;
    }

    &.from-me {
      background: rgba(64, 126, 201, 0.16);
    }
  }

  .message-bar {
    position: relative;
    bottom: -20px;
  }

  .component-page-header {
    margin-left: -32px;
    margin-right: -32px;
  }

  .chat-body-component--bottom {
    margin-left: 0;
    margin-right: 0;
  }

  .component-dialog__inner {
    height: 100%;
    position: relative;
    box-shadow: 0 8px 16px rgb(0 0 0 / 14%), inset 0 0 0 0 rgb(32 31 30 / 12%) !important;
    padding: 0 !important;
  }

  .component-dialog__content {
    height: 100%; // TODO из за этого пропадает курсов в Сафари
    color: inherit !important;

    .link-area {
      color: #407EC9;
    }
  }

  .chat-body-component {
    height: calc(100% - 64px);
    background-color: $white;
  }

  .component-base-container.focused {
    box-shadow: unset !important;
  }

  .component-message-action-wrap.inside {
    .actions-area {
      display: flex !important;
      right: 8px;
      .tooltip__wrap {
        display: none;

        &:first-child {
          display: flex;
        }
      }
    }
  }

  .component-input-main-wrap {
    padding-top: 20px;
    box-sizing: border-box;

    .component-file-drop-wrap {
      background: #f3f3f3;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #201F1E;
    }

    .component-base-container.with-rounded {
      background: #f3f3f3;
    }

    .component-toolbar.from-outside {
      padding-left: 4px;
      padding-right: 4px;
    }

    // TODO скрыть кнопку смайлов
    .toolbar-divider {
      display: none;
    }

    .tools-left {
      .component-icon-button {
        &:last-child {
          display: none;
        }
      }
    }

    div[data-contents=true] {
      max-height: 400px;
      overflow: hidden;
      overflow-y: auto;
    }
  }

}

.chat-body-component--md {
  padding: 0 0 20px !important;
}

#dialog-root .component-dialog__inner{
  padding: 0 32px !important;
}

.actions-area{
  display: none !important;
}

.test-content {
  flex-grow: 1;
  padding: 0 40px 40px;
  display: flex;
  justify-content: center;
  height: 100%;

  --blue-5-light: #E2E7ED;
  --yellow-1: #EFBE2C;
  --green-1: #0EAD69;
}
