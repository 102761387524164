@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.text-input {
  width: 100%;
}

.drop-file-item {
  height: 56px;
  min-height: 56px;
  border: 1px dashed;
  @include theme-aware('border-color', 'stroke-opacity');
  position: relative;

  input[type=file] {
    display: none;
  }

  .component-file-drop-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
  }
}
