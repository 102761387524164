@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';

.splash-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include theme-aware('background-color', 'bg-body');
}

.splash {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation-duration: 1.6s;
    animation-name: zoom;
    animation-iteration-count: infinite;
  }
}

@keyframes zoom {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
  }
}

#progress {
  display: none;
  width: 100%;
  max-width: 400px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @include theme-aware('color', 'text-100');
}

#progress.active {
  display: flex;
}

.progress-line {
  border-radius: 4px;
  height: 5px;
  margin: 8px 0;
  border: 1px solid;
  position: relative;
  overflow: hidden;
  width: 400px;
  @include theme-aware('border-color', 'stroke-opacity');

  span {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 20%;
    @include theme-aware('background-color', 'stroke-opacity');
  }
}
