@import '../../../node_modules/@clout-team/web-components/build/assets/styles/variables.scss';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/color-palette';
@import '../../../node_modules/@clout-team/web-components/build/assets/styles/main-colors';

.page-top {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.test-logo {
  text-transform: uppercase;
  font: $footnote;
  display: flex;
  align-items: center;

  svg {
    width: 30px;
    height: 30px;
    margin-right: 8px;

    path {
      fill: $action;
    }
  }
}
