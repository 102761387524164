.not-found {
  background-color: #F5F5F5;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/img/home-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  position: relative;

  &--content {
    width: 540px;
    height: 292px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 50%;
    margin-left: -270px;
    top: 50%;
    margin-top: -146px;
  }

  &--link {
    width: 120px;
    height: 120px;
    margin-bottom: 32px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &--title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 8px;
  }

  .on-main {
    margin-top: 24px;
  }
}
