.home-page {
  background-color: #F5F5F5;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-image: url("../../assets/img/home-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;

  &-content {
    flex-grow: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-welcome {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 586px;
    margin: 0 auto;
    align-items: center;
    position: relative;
    z-index: 1;
    bottom: 78px;

    &--logo {
      width: 56px;
      height: 56px;

      img {
        display: block;
        width: 56px;
        height: 56px;
      }
    }

    &--text {
      font-size: 40px;
      line-height: 52px;
      font-weight: bold;
      text-align: center;
    }

    &--description {
      text-align: center;
    }
  }

  &--button {
    margin-top: 40px;
  }

  .img-left, .img-right {
    position: absolute;
    bottom: 0;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .img-left {
    width: 521px;
    height: 390px;
    left: 154px;
  }

  .img-right {
    width: 647px;
    height: 485px;
    right: -24px;
  }
}

.add-user-dialog {

  .drop-file-item {
    background-color: #f5f5f5;
  }
}
